
/**
 * Common two-column, alternating photo-left / text-right layout
 */
.section--image-text {

	

	.intro {

		margin-bottom: 40px;
	}

	.section-layout {
		
		margin-bottom: 30px;

		@include respond-to('md'){
			margin-bottom: 40px;
		}
		
		text-align: center;

		.title {

			font-size: $font-size__h3;

		}

		p {

			padding-bottom: 0;	

			@include respond-to('sm'){
				padding-bottom: 40px;	
			}

		}

		.button{

			&[href^="http://"]:not([href*="jayheal.dev"]),
			&[href^="https://"]:not([href*="jayheal.dev"]), 
			&[href^="//"]:not([href*="jayheal.dev"]){

				&:after{
					content:'EXT';
					display: inline-block;
					margin-left: $base-size;
				}
			}


		}

		@include respond-to('sm') {
			.button {
				float: right;
			}
		}


	}

	

}

// Alternate left-right layout

.section--image-text.alternate {

	.section-layout {
		
		&:nth-child(odd) {
		
			@include respond-to('sm') {
				
					direction: rtl;
					text-align: left;
				

				.button {

					float: left;
				}
			}
		}

		&:nth-child(even) {
		
			@include respond-to('sm') {
				.button {
					float: right;
				}
			}
		}

	}

}