@mixin build-gutters($size-key, $size-value, $matrix: null) {
  $side: 'left';
  $matrix-class: '';
  $matrix-class-child: '';
  $matrix-class-modifier: '';
  $size-key: '-' + $size-key;

  @if $matrix {
    $side: 'bottom';
    $matrix-class-modifier: '--matrix';
    $matrix-class: '.#{$nb-namespace}o-grid' + $matrix-class-modifier;
    $matrix-class-child: $matrix-class + ' > ';
  }

  #{$matrix-class}.#{$nb-namespace}o-grid--gutter#{$size-key} {
    margin-#{$side}: -$size-value;
  }

  #{$matrix-class}.#{$nb-namespace}o-grid--gutter#{$size-key} > .#{$nb-namespace}o-grid__item {
    padding-#{$side}: $size-value;
  }

  .#{$nb-namespace}o-grid__item > #{$matrix-class}.#{$nb-namespace}o-grid--gutter#{$size-key} {
    width: calc(100% + #{$size-value});
  }

  @each $bp-key, $bp-value in $nb-breakpoints {
    @include nb-respond-to($bp-key) {
      #{$matrix-class}.#{$nb-namespace}o-grid--gutter#{$size-key}\@#{$bp-key} {
        margin-#{$side}: -$size-value;
      }

      #{$matrix-class}.#{$nb-namespace}o-grid--gutter#{$size-key}\@#{$bp-key} > .#{$nb-namespace}o-grid__item {
        padding-#{$side}: $size-value;
      }

      .#{$nb-namespace}o-grid__item > #{$matrix-class}.#{$nb-namespace}o-grid--gutter#{$size-key}\@#{$bp-key} {
        width: calc(100% + #{$size-value});
      }
    }
  }
}

@mixin nb-gutters {
  @each $key, $value in $nb-grid-gutter-sizes {
    @include build-gutters($key, $value);
    @include build-gutters($key, $value, 'matrix');
  }
}
