.#{$nb-namespace}o-grid {
  min-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

@include nb-gutters;

@if $nb-use-grid-zero-font-size {
  .#{$nb-namespace}o-grid {
    font-size: 0;
  }

  .#{$nb-namespace}o-grid__item {
    font-size: $nb-base-font-size;
  }
}

.#{$nb-namespace}o-grid--equal-height,
.#{$nb-namespace}o-grid--equal-height > .#{$nb-namespace}o-grid__item {
  display: flex;
}

.#{$nb-namespace}o-grid--equal-height > *,
.#{$nb-namespace}o-grid--equal-height > .#{$nb-namespace}o-grid__item > * {
  width: 100%;
}

.#{$nb-namespace}o-grid--equal-height {
  flex-wrap: wrap;
}

.#{$nb-namespace}o-grid--center > .#{$nb-namespace}o-grid__item {
  vertical-align: middle;
}

.#{$nb-namespace}o-grid--bottom > .#{$nb-namespace}o-grid__item {
  vertical-align: bottom;
}

.#{$nb-namespace}o-grid--reverse {
  direction: rtl;
  text-align: left;
}

.#{$nb-namespace}o-grid--reverse > .#{$nb-namespace}o-grid__item {
  direction: ltr;
  text-align: left;
}

.#{$nb-namespace}o-grid__item {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 1px;
  vertical-align: top;
  text-align: left;
}
