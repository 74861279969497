/**
 * Common three-col layout
 */
.section--three-col {
	
	margin-bottom: 40px;

	.section-layout {
		margin-bottom: 40px;
	}

}