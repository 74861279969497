$colour__white			   : #fff;
$colour__black			   : #000;
$colour__dark-grey		   : #282828;
$colour__grey			   : #535353;
$colour__blue			   : blue;

$colour__highlight		   : $colour__blue;

$colour__error			   : #c30000;

$colour__primary--green	   : #00B2A9;
$colour__primary--black	   : #1e1e1e;

$colour__secondary--blue   : #002F5F;
$colour__secondary--green  : #009AA6;

$colour__neutral--blue     : #c1e2e5;
$colour__neutral--mushroom : #dad7cb;