html {
  min-height: 100%;

  @include nb-root-sizing;
}

body {
  font-size: $nb-base-font-size;
}

/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dl,
blockquote,
p,
address,
table,
fieldset,
figure,
pre,
hr {
  margin-bottom: $nb-spacing-unit;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount.
 */
ul,
ol,
dd {
  margin-left: ($nb-spacing-unit * 2);
}
