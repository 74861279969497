.section--gallery {


	&__item {
		img {
			width: 100%;
			height: 100%;
		}
	}

}