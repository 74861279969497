.#{$nb-namespace}u-text-left {
  text-align: left !important;
}

.#{$nb-namespace}u-text-center {
  text-align: center !important;
}

.#{$nb-namespace}u-text-right {
  text-align: right !important;
}
