
.project-item{

	&__desc {

		text-align: left;


		@include respond-to('sm') {
		}

		.button {
			border: none;
			padding: 0;
			text-align: left;

			@include respond-to('sm'){
				border: 4px solid $colour__dark-grey;
				text-align: center;
				padding: (1rem / 2) 2rem;				
			}			
		}

	}


	&__thumbnail {

		margin-bottom: 20px;

		@include respond-to('sm') {
			margin-bottom: 0;

		}

	}

}