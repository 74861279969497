@mixin nb-offsets($action, $fractions) {
  $property: '';
  $class: $action + '-';

  @if $action == 'push' {
    $property: 'left';
  } @elseif $action == 'pull' {
    $property: 'right';
  } @else {
    $property: 'width';
    $class: '';
  }

  @each $key, $value in $fractions {
    $modifier: nb-str-replace($key, '/', '\\/');
    $offset-value: (($value * 100) * 1%);

    .#{$nb-namespace}u-#{$class}#{$modifier} {
      #{$property}: $offset-value;
    }
  }

  @each $bp-key, $bp-value in $nb-breakpoints {
    @include nb-respond-to($bp-key) {
      @each $key, $value in $fractions {
        $modifier: nb-str-replace($key, '/', '\\/');
        $offset-value: (($value * 100) * 1%);
        .#{$nb-namespace}u-#{$class}#{$modifier}\@#{$bp-key} {
          #{$property}: $offset-value;
        }
      }
    }
  }
}
