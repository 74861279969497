.#{$nb-namespace}o-matrix-list {
  @include nb-bare-list;
}

.#{$nb-namespace}o-matrix-list__item {
  display: inline-block;
}

@include nb-list-spacing('matrix-list', 'left');
@include nb-list-spacing('matrix-list', 'bottom');
