@mixin nb-root-sizing {
  @each $key, $value in $nb-root-sizing {
    @if $key == 'default' {
      font-size: $value;
    }

    @else {
      @if map-has-key($nb-breakpoints, $key) {
        @include nb-respond-to($key) {
          font-size: $value;
        }
      }

      @else {
        @warn 'Unfortunately the supplied nb-root-sizing key `#{$key}` '
        + 'does not match a corresponding key in the `$nb-breakpoints` map or == `default`';
      }
    }
  }
}
