@mixin nb-respond-to($bp, $breakpoints: $nb-breakpoints) {
  @if map-has-key($breakpoints, $bp) {
    @media (min-width: #{map-get($breakpoints, $bp)}) {
      @content;
    }
  }

  @else {
    @if (str-index($bp, 'max-')) {
      $max-bp: nb-str-replace($bp, 'max-', '');

      @if map-has-key($breakpoints, $max-bp) {
        @media (max-width: #{(map-get($breakpoints, $max-bp)-1)}) {
          @content;
        }
      }
    }

    @elseif (str-index($bp, 'min-')) {
      $min-bp: nb-str-replace($bp, 'min-', '');

      @if map-has-key($breakpoints, $min-bp) {
        @media (min-width: #{map-get($breakpoints, $min-bp)}) {
          @content;
        }
      }
    }

    @else {
      @warn 'Unfortunately, no value could be retrieved from `#{$bp}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
}
