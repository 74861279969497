.#{$nb-namespace}o-inline-list {
  @include nb-bare-list;
}

.#{$nb-namespace}o-inline-list__item {
  display: inline-block;
  vertical-align: middle;
}

@include nb-list-spacing('inline-list', 'left');
