@mixin nb-spacing-push-soft($action, $sizes) {
  $property: '';
  $directions: '';

  @if $action == 'push' {
    $property: 'margin';
    $directions: '-bottom', '-left';
  } @else {
    $property: 'padding';
    $directions: '-top', '-right', '-bottom', '-left', '';
  }

  @each $key, $value in $sizes {
    @each $direction in $directions {
      .#{$nb-namespace}u-#{$action}#{$direction}-#{$key} {
        #{$property}#{$direction}: $value !important;
      }
    }

    @each $bp-key, $bp-value in $nb-breakpoints {
      @include nb-respond-to(#{$bp-key}) {
        @each $direction in $directions {
          .#{$nb-namespace}u-#{$action}#{$direction}-#{$key}\@#{$bp-key} {
            #{$property}#{$direction}: $value !important;
          }
        }
      }
    }
  }
}

@mixin nb-flush-hard($action) {
  $property: '';

  @if $action == 'flush' {
    $property: 'margin';
  } @else {
    $property: 'padding';
  }

  $directions: '-top', '-right', '-bottom', '-left';
  .#{$nb-namespace}u-#{$action} {
    #{$property}: 0 !important;
  }

  @each $direction in $directions {
    .#{$nb-namespace}u-#{$action}#{$direction} {
      #{$property}#{$direction}: 0 !important;
    }
  }

  @each $key, $value in $nb-breakpoints {
    @include nb-respond-to(#{$key}) {
      .#{$nb-namespace}u-#{$action}\@#{$key} {
        #{$property}: 0 !important;
      }

      @each $direction in $directions {
        .#{$nb-namespace}u-#{$action}#{$direction}\@#{$key} {
          #{$property}#{$direction}: 0 !important;
        }
      }
    }
    $max-key: 'max-' + $key;
    @include nb-respond-to(#{$max-key}) {
      .#{$nb-namespace}u-#{$action}\@#{$max-key} {
        #{$property}: 0 !important;
      }

      @each $direction in $directions {
        .#{$nb-namespace}u-#{$action}#{$direction}\@#{$max-key} {
          #{$property}#{$direction}: 0 !important;
        }
      }
    }
  }
}
