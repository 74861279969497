@function rem($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
	$pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
	$context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}


/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
/// Usage: @include respond-to('xs') { ... }
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($nb-breakpoints, $breakpoint) {
	// Prints a media query based on the value
	@media (min-width: map-get($nb-breakpoints, $breakpoint)) {
	  @content;
	}
  }
 
  // If the key doesn't exist in the map
  @else {
	@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+ "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}


// Burger parts
//
// (---) top    -> &::before
// [---] middle -> &
// (---) bottom -> &::after
/*
	Example:

	.menu-button {
		display: inline-block;
		padding: .5em;
		background-color: #fafafa;
		border: 1px solid #ccc;
	}

	.burger-icon {
		@include burger(25px, 3px, 5px, #444);
	}

	.menu-button.is-active .burger-icon {
		@include burger-to-cross;
	}

	<a href="#" class="menu-button" id="menuButton">
		<span class="burger-icon"></span>
	</a>

	<script>
		var menuButton = document.getElementById('menuButton');
		menuButton.addEventListener('click', function (e) {
			menuButton.classList.toggle('is-active');
			e.preventDefault();
		});
	</script>

*/

// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
	$burger-height: $height !global;
	$burger-gutter: $gutter !global;

	position: relative;
	margin-top: $height + $gutter;
	margin-bottom: $height + $gutter;

	@if $sass-burger-add-vendor-prefixes {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	user-select: none;

	// 1. Fixes jagged edges in Firefox, see issue #10.
	&, &::before, &::after {
		display: block;
		width: $width;
		height: $height;
		background-color: $color;
		outline: 1px solid transparent; // 1
		@if $border-radius != 0 {
			border-radius: $border-radius;
		}

		@if $sass-burger-add-vendor-prefixes {
			-webkit-transition-property: background-color, -webkit-transform;
			-moz-transition-property: background-color, -moz-transform;
			-o-transition-property: background-color, -o-transform;
		}
		transition-property: background-color, transform;

		@if $sass-burger-add-vendor-prefixes {
			-webkit-transition-duration: $transition-duration;
			-moz-transition-duration: $transition-duration;
			-o-transition-duration: $transition-duration;
		}
		transition-duration: $transition-duration;
	}

	&::before, &::after {
		position: absolute;
		content: "";
	}

	&::before {
		top: -($height + $gutter);
	}

	&::after {
		top: $height + $gutter;
	}
}


// Select parts of the burger
@mixin burger-parts {
	&, &::before, &::after {
		@content;
	}
}

@mixin burger-top {
	&::before {
		@content;
	}
}

@mixin burger-middle {
	& {
		@content;
	}
}

@mixin burger-bottom {
	&::after {
		@content;
	}
}


// Burger animations
@mixin burger-to-cross($color: auto) {
	& {
		background-color: transparent;
	}
	@if ($color != auto) {
		&::before, &::after {
			background-color: $color;
		}
	}
	&::before {
		@if $sass-burger-add-vendor-prefixes {
			-webkit-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
			-moz-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
			-ms-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
			-o-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
		}
		transform: translateY($burger-gutter + $burger-height) rotate(45deg);
	}
	&::after {
		@if $sass-burger-add-vendor-prefixes {
			-webkit-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
			-moz-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
			-ms-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
			-o-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
		}
		transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
	}
}
