$font__main: 'Montserrat', sans-serif;
$font__logo: 'Roboto', sans-serif;

$base-size:					16px;
$base-font-size:			14px;

$font-size__body: 			20px;
$font-size__body--mobile:	14px;

$line-height__body:			rem( 32px ); // Legacy? Use $font-size__body * 1.05 instead.

$font-size__logo: 			rem( 48px );
$font-size__logo--mobile:	rem( 28px );

$font-size__navigation: 	rem( 24px );

$font-size__h2: 			rem( 36px );
$font-size__h2--mobile:		rem( 20px );
$line-height__h2: 			rem( 42px );
$line-height__h2--mobile:	rem( 26px );

$font-size__h3: 			rem( 28px );

$font-size__h4: 			rem( 20px );