footer {
	padding:  $base-size*2 0;
	background-color: $colour__primary--green;
	color: $colour__white;

	@include respond-to('sm'){
		margin-top: $base-size * 4;
	}

	a {
		color: $colour__white;
		
		&:visited {
			color: $colour__white;
		}

		&:hover {
			color: $colour__highlight;
		}
	}


	.icon {
		float: left;
		margin-right: $base-size;
		max-width: 120px;

		margin-bottom: $base-size*2;
		@include respond-to('sm'){
			margin-bottom: 0;
			max-width: 80px;
		}
	}

	.copyright {
		float: left;
	}


	.socials {
		display: flex;
		justify-content: space-between;

		@include respond-to('sm'){
			justify-content: space-around;

		}
		list-style: none;
		padding: 0;
		margin: 0;
	}
}