/**
 * Extendable set styles
 *
 * WARNING: Do not modify these styles directly as they are extended by other style declarations
 */

#site-title{
	position: relative;
	font-size: $font-size__h2--mobile;
	line-height: $line-height__h2--mobile;
	margin-bottom: 30px;

	border: none;
	border-bottom-width: 5px;
	border-bottom-style: solid;
	border-bottom-color: $colour__primary--green;

	display: inline-block;
	padding: 0 20px 5px 20px;

	@include respond-to('sm'){
		font-size: $font-size__h2;
		line-height: $line-height__h2;
		margin-bottom: 50px;
		padding: 0 20px 15px 20px;

	}

	/*
	&:after{
		
		content: '';
		position: absolute;
		width: 50%;
		left: 25%;
		bottom: -5px;
		border-bottom: 4px solid $colour__white;

	}		
	*/
}

#link-border{

	text-decoration: none;
	border-bottom: 2px solid $colour__white;
	border-color: transparent;
	transition: border-color 0.3s ease-in-out;

	&:hover{
		border-bottom: 2px solid $colour__white;
	}
}

#button{

	display: inline-block;
	font-weight: 500;
	font-size: $font-size__body--mobile;
	color: $colour__dark-grey;
	text-decoration: none;
	border: 0;
	text-align: center;
	padding: (1rem / 2) 2rem;

	@include respond-to( 'sm' ){
		font-size: $font-size__body;
	}


	&:hover{

	}

	&.button--large{
		width:100%;
	}

	&.button--arrow--right {

		&:after {
			content: '>';
			padding-left: 1rem;
			position: absolute;
			right: 1.5rem;
		}

	}
	&.button--arrow--left {

		&:after {
			content: '<';
			padding-right: 1rem;
			position: absolute;
			left: 1.5rem;
		}

	}

}