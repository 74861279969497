@mixin nb-list-spacing($class, $side) {
  $modifier: '--spaced';
  @if $class == 'matrix-list' {
    $modifier: '';
  }

  @each $key, $value in $nb-list-spacing {
    $compound-class: '#{$nb-namespace}o-#{$class}#{$modifier}-#{$key}';

    .#{$compound-class} {
      margin-#{$side}: -$value;
    }

    .#{$compound-class} > .#{$nb-namespace}o-#{$class}__item {
      padding-#{$side}: $value;
    }

    @each $bp-key, $bp-value in $nb-breakpoints {
      @include nb-respond-to($bp-key) {
        .#{$compound-class}\@#{$bp-key} {
          margin-#{$side}: -$value;
        }

        .#{$compound-class}\@#{$bp-key} > .#{$nb-namespace}o-#{$class}__item {
          padding-#{$side}: $value;
        }
      }
    }
  }
}
