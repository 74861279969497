/**
 * Thumbnail and text list of key points
 */
.section--keypoints {
	
	.section-layout {
		
	}

	.item {

		margin-bottom: $base-size*2;
	}

}