@mixin uniformed-list-properties($bp: null) {
  $bp-class: '';
  @if $bp {
    $bp-class: '\\@#{$bp}';
  }

  .#{$nb-namespace}o-uniformed-list#{$bp-class} {
    @include nb-bare-list;
    display: table;
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }

  .#{$nb-namespace}o-uniformed-list#{$bp-class} > .#{$nb-namespace}o-uniformed-list__item {
    display: table-cell;
    padding: 0 ($nb-spacing-unit * 0.5);
  }
}

@mixin nb-uniformed-list {
  @include uniformed-list-properties;

  @each $bp-key, $bp-val in $nb-breakpoints {
    @include nb-respond-to($bp-key) {
      @include uniformed-list-properties($bp-key);
    }
  }
}
