/*
Inspired by Inuit's Reset, plus a few additions of my own:
A more considered reset; more of a restart...
As per: csswizardry.com/2011/10/reset-restarted
*/

// Setup border-box.
* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  // Better font rendering.
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

// The usual.
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
figure,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
}

// Give a help cursor to elements that give extra info on `:hover`.
abbr[title],
dfn[title] {
  cursor: help;
}

/*
1. make image fluid & maintain aspect ratio.
2. So that `alt` text is visually offset if images don’t load.
*/
img {
  max-width: 100%; // [1]
  height: auto; // [1]
  font-style: italic; // [2]
}

//Remove borders from images wrapped in anchors.
a img {
  border: 0;
}

// Remove padding and border from buttons.
button {
  border: 0;
  padding: 0;
}

// Give labels elements some cursor interactions...
label {
  cursor: pointer;
}
