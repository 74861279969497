
/*
* Layout debugging display
*/
@if $debug {
	body {

		&:after {

			position: fixed;
			bottom: 0;
			right: 0;
			display: block;
			padding: .5em 1em;
			color: white;
			background-color: red;			

			content: 'default mobile';

			@include respond-to('xs') {
				content: 'xs';
			}

			@include respond-to('sm') {
				content: 'sm';
			}

			@include respond-to('md') {
				content: 'md';
			}

			@include respond-to('lg') {
				content: 'lg';
			}

			@include respond-to('xl') {
				content: 'xl';
			}



		}
	}

}