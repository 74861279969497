/**
 * Defaults
 */

html,
body {
	height: 100%;
	margin: 0;
}

body,
p{
	font-family: $font__main;

	font-size: $font-size__body--mobile;
	line-height: $font-size__body--mobile * 1.5;

	@include respond-to('sm') {

		font-size: $font-size__body;
		line-height: $font-size__body *1.5;

	}
}


a {
	transition: color 0.15s ease-in-out;
}


/**
* Site-wide styles
*/

.overlay{

	width: 100%;
	height:100%;
	min-height:100%;/*for mozilla*/

	left: 0;
	z-index: 1;
	position: absolute;
	//opacity: .5;
	z-index: 0;
	background-color: rgba(0,0,0,0.7);

}


.section{


	padding-top: 60px;

	@include respond-to('md') {

		padding-top: 80px;

	}
	

	

	&--places{


	}


	&--grid {

		

	}

}

.intro{}


.content{
	text-align: center;

	.title{
		
		@extend #site-title;

	}

	a {		
		color: $colour__dark-grey;

	}
}


/**
* Buttons
*/

.button{

	@extend #button;

}
