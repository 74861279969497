.section--places {

	.logo {
		text-align: center;
		//border: 1px solid red;

		img {

			//border: 1px solid blue;
		}

		a {

			width: 100%;
			height: 100%;

			img {

			}
		}
	}
}


