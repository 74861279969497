
/**
 * Navigation
 */

.navbar {
	top: 0;
	left: 0;
	padding-top: 20px;
	width: 100%;
	z-index: 3;
	background-color: transparent;
	background-image: none;
	border: 0;
	border-radius: 0;
	position: relative;



	&.navbar-inverse, 
	&.navbar-inverse .navbar-inner {
		background-color: transparent;

	}

	&.navbar-fixed {

		background-color: $colour__white;
		color: $colour__dark-grey;

		@include respond-to('sm') {

			position: fixed;
			background-color: $colour__white;
			color: $colour__dark-grey;
		
			.logo,
			.main-navigation {
				a {
					color: $colour__dark-grey;
					transition: 
						color 0.15s ease-in-out,
						border-color 0.3s ease-in-out;

						&:hover{
							border-color: $colour__dark-grey;
						}
				}
			}

			.title {
				font-size: 30px;
				line-height: 38px;
				padding-bottom: $base-size;
				transition: font-size 0.2s ease-in-out;
			}

			.tagline {
				display: none;
			}

			.navbar-inner {
				border-bottom: 4px solid $colour__dark-grey;
			}
		}

	}

}

.navbar {
	*position: relative;
	*z-index: 2;
	margin-bottom: 20px;
	overflow: visible;

	.title {
		@include respond-to('sm') {

			transition: font-size 0.2s ease-in-out;
		}

	}
}


body.home {
	.navbar {
		position: fixed;
	}
}




.logo {
	a {
		transition: none;
	}
}


.main-navigation{


	font-size: $font-size__navigation;
	font-weight: 500;

	
	@include respond-to('sm') {
		display: block;
	}

	@include respond-to('md') {
		position: absolute;
		right:0;
	}

	a{
		@extend #link-border;
	}

	@include respond-to('sm') {

		h3 {
			display: none;
		}

	}

	&>ul {

		display: block;

		@include respond-to('sm') {
			text-align: center;
			border: 0;
		}	
		@include respond-to('md') {
			text-align: inherit;
		}

		&>li{

			display: block;
			border-bottom: 1px solid $colour__grey;
			padding: 10px 0;

			@include respond-to('sm') {
				display: inline-block;
				border: 0;
				padding: inherit;
			}
		}
	}

	.nav-menu{

	}
}


.navbar > .menu-button {
	position: absolute;
	right: 10px;
	top: 10px;
}
.navbar .menu-container .menu-button {
	display: none;
	position: absolute;
	right: 10px;
	top: 10px;
}

.menu-button {
	display: block;
	padding: .5em;
	background-color: transparent;
	position: absolute;
	right: 10px;
	top: 10px;
	opacity: 1;

	@include respond-to('sm') {

		display: none;

	}

}

.menu-container{

	padding: 20px;
	position: fixed;
	height: 100%;
	background-color: $colour__dark-grey;
	transition: right 0.25s ease-in-out;
	top: 0;
	right: -100%;
	z-index: 2;

	@include respond-to('sm') {
		border: 0;
		background-color: transparent;
		position: relative;
		padding: 0 20px 0;
		right: auto;

	}
	&.reveal {
		right: 0;
		//overflow-y: scroll;

		// Page menu button
		& + .menu-button{
			display: none;
			opacity: 0;
			transition: opacity 1s ease-in;

		}

		//Slide-out menu button
		.menu-button {
			display: block;

			@include respond-to('sm'){
				display: none;
			}
		}

	}

}


.burger-icon {
	@include burger(25px, 3px, 5px, $colour__white);
}

.navbar-fixed { 
	.burger-icon {
		background-color: $colour__dark-grey;

		&::after,
		&::before {
			background-color: $colour__dark-grey;
		}

	}

	.logo {
		.title {
			color: $colour__dark-grey;
		}
		.tagline {
			color: $colour__dark-grey;
		}

	}
}

.menu-button.is-active .burger-icon {
	@include burger-to-cross;
}
