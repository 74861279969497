
// Styles for "Useful links" section
.links {


	&__item {

		margin-bottom: $base-size;

		&:last-child{
			margin-bottom: 0;
		}

		@include respond-to('xs') {

			&:last-child{
				margin-bottom: $base-size;
			}
		}


		a {
			color: $colour--dark-grey;
		}
	}

	&-inner {
		text-align: center;
		border: 4px solid $colour--dark-grey;	
		padding: $base-size;	
	}

}